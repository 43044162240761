import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos'; 
import 'aos/dist/aos.css';


class portfolio extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
    }
    
    render() {
        return (
            <div className="container">
                <section id="hero" className="text-center sectionTitle">
                    <h1 id="tagline">Portfolio</h1>
                </section>
                <section id="serv-showcase">
                    <div className="row">

                        {/* Free Learning Course */}
                        <div id="serv-tl-writing" data-aos="fade-up" data-aos-delay="100">
                            <div className="border-bottom">
                                <h2 className="text-left">Free Learning Course</h2>
                                <div className="project-link">
                                    <a className="project_link" href="https://www.freelearningcourse.com/" target="_blank" rel="noopener noreferrer"> <i className="la la-external-link-square"></i> </a>
                                </div>
                                <p>
                                    We are on mission that every people can learn and keep updated yourself. But some peoples are don't get enough financial situation to learn new things. 
                                    So, We can arrange best tutor with top premium course at FREE of cost.
                                </p>
                                <p> Free Learning Course website provides a premium course copons that help you to get that course FREE. </p>
                                    
                                <p> Coupon code is availablit to use for limited number of peoples of First come first server senario.
                                    So Please visit site early to get that course at FREE OF COST. </p>

                                <p>
                                    Regrads,<br/>
                                    Free Learning Course,<br/>
                                    Keep Learning Keep Updated
                                </p>
                            </div>
                            <div>
                                <img src="./assets/img/portfolio/free_learning_course.png" className="portfolio_img" alt="Free Learning Course"/>
                            </div>
                            <div>
                                <h3 className="bg-foot-color">Technology</h3>
                                    <span className="tag_programming">## Programming ##</span>
                                    <p>
                                        <span className="tag">WordPress</span>
                                    </p>

                                    <span className="tag_programming">## Database ##</span>
                                    <p>
                                        <span className="tag">MYSQL</span>
                                    </p>

                                    <span className="tag_programming">## User - Interface ##</span>
                                    <p>
                                        <span className="tag">Bootstrap</span>
                                        <span className="tag">CSS3</span>
                                        <span className="tag">HTML5</span>
                                    </p>

                                    <span className="tag_programming">## Script ##</span>
                                    <p>
                                        <span className="tag">Javascript</span>
                                        <span className="tag">Jquery</span>
                                    </p>

                                    <span className="tag_programming">## Server ##</span>
                                    <p>
                                        <span className="tag">Google Cloud Platform</span>
                                    </p>

                                    <span className="tag_programming">## CDN Network ##</span>
                                    <p>
                                        <span className="tag">Cloudflare</span>
                                    </p>
                            </div>
                        </div>

                        {/* Woohoodoodh */}
                        <div id="serv-tl-writing" data-aos="fade-up" data-aos-delay="100">
                            <div className="border-bottom">
                                <h2 className="text-left">WooHooDoodh</h2>
                                <div className="project-link">
                                    <a className="project_link" href="https://woohoodoodh.com/" target="_blank" rel="noopener noreferrer"> <i className="la la-external-link-square"></i> </a>
                                </div>
                                <p className="mb-0">Design Clean interfaces for complex invoicing, inventory and subscription Web Application with tiny feeling interface.</p>
                            </div>
                            <div>
                                <img src="./assets/img/portfolio/woohoo.jpg" className="portfolio_img" alt="WooHooDoodh"/>
                            </div>
                            <div>
                                <h3 className="bg-foot-color">Technology</h3>
                                    <span className="tag_programming">## Programming ##</span>
                                    <p>
                                        <span className="tag">PHP</span>
                                    </p>

                                    <span className="tag_programming">## Database ##</span>
                                    <p>
                                        <span className="tag">MYSQL</span>
                                    </p>

                                    <span className="tag_programming">## User - Interface ##</span>
                                    <p>
                                        <span className="tag">Bootstrap</span>
                                        <span className="tag">CSS3</span>
                                        <span className="tag">HTML5</span>
                                    </p>

                                    <span className="tag_programming">## Script ##</span>
                                    <p>
                                        <span className="tag">Javascript</span>
                                        <span className="tag">Jquery</span>
                                        <span className="tag">Google API</span>
                                        <span className="tag">SweetAlert</span>
                                        <span className="tag">Chart's API</span>
                                    </p>
                            </div>
                        </div>

                        {/* Indian zebu */}
                        <div id="serv-tl-writing" data-aos="fade-up" data-aos-delay="100">
                            <div className="border-bottom">
                                <h2 className="text-left">Indian Zebu</h2>
                                <div className="project-link">
                                    <a className="project_link" href="http://indianzebu.com/" target="_blank" rel="noopener noreferrer"> <i className="la la-external-link-square"></i> </a>
                                </div>
                                <p className="mb-0">Design a website that gives a feel of the village, all the images and sound are used real in this website that gives more emotional attachment to this website.</p>
                            </div>
                            <div>
                                <img src="./assets/img/portfolio/indian_zebu.jpg" className="portfolio_img" alt="WooHooDoodh"/>
                            </div>
                            <div>
                                <h3 className="bg-foot-color">Technology</h3>
                                    <span className="tag_programming">## Programming ##</span>
                                    <p>
                                        <span className="tag">PHP</span>
                                    </p>

                                    <span className="tag_programming">## Database ##</span>
                                    <p>
                                        <span className="tag">MYSQL</span>
                                    </p>

                                    <span className="tag_programming">## User - Interface ##</span>
                                    <p>
                                        <span className="tag">Bootstrap</span>
                                        <span className="tag">CSS3</span>
                                        <span className="tag">HTML5</span>
                                    </p>

                                    <span className="tag_programming">## Script ##</span>
                                    <p>
                                        <span className="tag">Javascript</span>
                                        <span className="tag">Jquery</span>
                                        <span className="tag">Google API</span>
                                        <span className="tag">SweetAlert</span>
                                        <span className="tag">Chart's API</span>
                                    </p>
                            </div>
                        </div>

                        {/* Every thing is persoanl */}
                        <div id="serv-tl-writing" data-aos="fade-up" data-aos-delay="100">
                            <div className="border-bottom">
                                <h2 className="text-left">Everything is personal</h2>
                                <div className="project-link">
                                    <a className="project_link" href="https://www.everythingispersonal.com/" target="_blank" rel="noopener noreferrer"> <i className="la la-external-link-square"></i> </a>
                                </div>
                                <p className="mb-0">Design a website that gives a feel of the creativity, all the images and art-works and plays are used real in this website that gives more users attractions to this website.</p>
                            </div>
                            <div>
                                <img src="./assets/img/portfolio/eip.png" className="portfolio_img" alt="Everythingispersoanl"/>
                            </div>
                            <div>
                                <h3 className="bg-foot-color">Technology</h3>
                                    <span className="tag_programming">## Programming ##</span>
                                    <p>
                                        <span className="tag">PHP</span>
                                        <span className="tag">CodeIgniter</span>
                                    </p>

                                    <span className="tag_programming">## Database ##</span>
                                    <p>
                                        <span className="tag">MYSQL</span>
                                    </p>

                                    <span className="tag_programming">## User - Interface ##</span>
                                    <p>
                                        <span className="tag">Bootstrap</span>
                                        <span className="tag">CSS3</span>
                                        <span className="tag">HTML5</span>
                                        <span className="tag">Jquery UI</span>
                                    </p>

                                    <span className="tag_programming">## Script ##</span>
                                    <p>
                                        <span className="tag">Javascript</span>
                                        <span className="tag">Jquery</span>
                                        <span className="tag">SweetAlert</span>
                                    </p>
                            </div>
                        </div>

                        {/* Trigger System */}
                        <div id="serv-tl-writing" data-aos="fade-up" data-aos-delay="100">
                            <div className="border-bottom">
                                <h2 className="text-left">Trigger Systems</h2>
                                <div className="project-link">
                                    <a className="project_link" href="https://trigger.systems/" target="_blank" rel="noopener noreferrer"> <i className="la la-external-link-square"></i> </a>
                                </div>
                                <p>Trigger.Systems works on tackling the excessive use of water in irrigation, which represents 70% of the fresh water spent globally.</p>

                                <p>The actual control systems used in agriculture and landscape areas normally have an inappropriate water consumption values derived from mismanagement and empirical determination of necessities. </p>

                                <p>Trigger.Systems’s platform eases the control of irrigation systems using calculation models based on weather forecasts and plant conditions which can lead to 40% of water saving. </p>

                                <p>In this platform users can create their own customized solution by using the apps that best represent their systems and with this maximize the efficiency of their systems. Each app is design for a specific purpose and it has different intelligent models that control the systems.</p>

                                <p>
                                    Control all your systems in one unique platform:
                                    <br/>
                                    Irrigation, Pumps, Lakes, Fertilization, Hydroponics, Golf, Energy, Pollution.
                                </p>

                                <p>Save water and energy. </p>
                            </div>
                            <div>
                                <img src="./assets/img/portfolio/trigger-system.png" className="portfolio_img" alt="trigger.systems"/>
                            </div>
                            <div>
                                <h3 className="bg-foot-color">Technology</h3>
                                    <span className="tag_programming">## Programming ##</span>
                                    <p>
                                        <span className="tag">PHP</span>
                                        <span className="tag">CodeIgniter</span>
                                        <span className="tag">Twig</span>
                                    </p>

                                    <span className="tag_programming">## Database ##</span>
                                    <p>
                                        <span className="tag">MYSQL</span>
                                    </p>

                                    <span className="tag_programming">## User - Interface ##</span>
                                    <p>
                                        <span className="tag">Bootstrap</span>
                                        <span className="tag">CSS3</span>
                                        <span className="tag">HTML5</span>
                                        <span className="tag">Jquery UI</span>
                                    </p>

                                    <span className="tag_programming">## Script ##</span>
                                    <p>
                                        <span className="tag">Javascript</span>
                                        <span className="tag">Jquery</span>
                                        <span className="tag">Google map API</span>
                                        <span className="tag">SweetAlert</span>
                                        <span className="tag">Chart's API</span>
                                        <span className="tag">AM Chart API</span>
                                        <span className="tag">Google Analytics</span>
                                        <span className="tag">Jquery Alert</span>
                                    </p>

                                    <span className="tag_programming">## Script Management Tool ##</span>
                                    <p>
                                        <span className="tag">GitHub</span>
                                        <span className="tag">GitLab</span>
                                    </p>

                                    <span className="tag_programming">## Lightweight Communication ##</span>
                                    <p>
                                        <span className="tag">WebSocket</span>
                                        <span className="tag">MQTT</span>
                                    </p>

                                    <span className="tag_programming">## Local Enviroment Software  ##</span>
                                    <p>
                                        <span className="tag">Docker</span>
                                        <span className="tag">XAMPP</span>
                                    </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="hero">
                    <div id="btn-grp-header" className="d-flex align-items-center">
                        <Link id="colr-mode-toggle" role="button" className="d-flex align-items-center ml-0" to="/">
                            <i id="ic-colr-mode" className="la la-arrow-left"></i>
                            <p id="labl-colr-mode">Back to home</p>
                        </Link>
                    </div>
                </section>
            </div>
        );
    }

}

export default portfolio;