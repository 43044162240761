import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Header from './componet/headerComponet/header';
import Home from './componet/contentComponet/home';
import About from './componet/contentComponet/about-us';
import CareerHistroy from './componet/contentComponet/work-histroy';
import Portfolio from './componet/contentComponet/portfolio';
import Certificate from './componet/contentComponet/certificate';

function App() {
  return (
    <Router>
    <div className="App">
        <Header />
        <Route exact path="/" component={Home} />
        <Route exact path="/about-me" component={About} />
        <Route exact path="/career-histroy" component={CareerHistroy} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/certificate" component={Certificate} />
    </div>
    </Router>
  );
}

export default App;
