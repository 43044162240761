import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos'; 
import 'aos/dist/aos.css';


class certificate extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
    }
    
    render() {
        return (
            <div className="container">
                <section id="hero" className="text-center sectionTitle">
                    <h1 id="tagline">Certificate</h1>
                </section>
                <section id="serv-showcase">
                    <div className="row">
                        <div  className="col-md-6 mb-5"  data-aos="fade-up" data-aos-delay="100">
                            <div className="certificate-card">
                                <div className="certificate">
                                    <img src="./assets/img/certificate/analytics-b.png" className="portfolio_img" alt="Google Analytics for Beginners"/>
                                </div>
                                <div className="certificate-text">
                                    <i className="la la-certificate icon-40"></i>  <h4> Google Analytics for Beginners </h4>
                                </div>
                            </div>
                        </div>
                        <div  className="col-md-6 mb-5" data-aos="fade-up" data-aos-delay="100">
                            <div className="certificate-card">
                                <div className="certificate">
                                    <img src="./assets/img/certificate/analytics-ecom.png" className="portfolio_img" alt="Ecommerce Analytics From to Decisions"/>
                                </div>
                                <div className="certificate-text">
                                    <i className="la la-certificate icon-40"></i> <h4> Ecommerce Analytics From to Decisions </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div  className="col-md-6 mb-5" data-aos="fade-up" data-aos-delay="100">
                            <div className="certificate-card">
                                <div className="certificate">
                                    <img src="./assets/img/certificate/analytics-advance.png" className="portfolio_img" alt="Advance Google Analytics"/>
                                </div>
                                <div className="certificate-text">
                                    <i className="la la-certificate icon-40"></i> <h4> Advance Google Analytics </h4>
                                </div>
                            </div>
                        </div>
                        <div  className="col-md-6 mb-5" data-aos="fade-up" data-aos-delay="100">
                            <div className="certificate-card">
                                <div className="certificate">
                                    <img src="./assets/img/certificate/google-tag-manager.png" className="portfolio_img" alt="Google Tag Manager Fundamentals"/>
                                </div>
                                <div className="certificate-text">
                                    <i className="la la-certificate icon-40"></i> <h4> Google Tag Manager Fundamentals </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div  className="col-md-6 mb-5" data-aos="fade-up" data-aos-delay="100">
                            <div className="certificate-card">
                                <div className="certificate">
                                    <img src="./assets/img/certificate/digital-marketing.png" className="portfolio_img" alt="Google Tag Manager Fundamentals"/>
                                </div>
                                <div className="certificate-text">
                                    <i className="la la-certificate icon-40"></i> <h4> The Fundamentals Of Digital Marketing </h4>
                                </div>
                            </div>
                        </div>
                        <div  className="col-md-6 mb-5" data-aos="fade-up" data-aos-delay="100">
                            <div className="certificate-card">
                                <div className="certificate">
                                    <img src="./assets/img/certificate/google-ads.png" className="portfolio_img" alt="Introduction to App Campaigns"/>
                                </div>
                                <div className="certificate-text">
                                    <i className="la la-certificate icon-40"></i> <h4> Introduction to App Campaigns </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="hero">
                    <div id="btn-grp-header" className="d-flex align-items-center">
                        <Link id="colr-mode-toggle" role="button" className="d-flex align-items-center ml-0" to="/">
                            <i id="ic-colr-mode" className="la la-arrow-left"></i>
                            <p id="labl-colr-mode">Back to home</p>
                        </Link>
                    </div>
                </section>
            </div>
        );
    }

}

export default certificate;