import React from 'react';
import { Link } from 'react-router-dom';

function openMenu(){
    document.getElementById("myNav").style.height = '100%';
}

function closeMenu(){
    document.getElementById("myNav").style.height = '0%';
}

function App() {
  return (
    <div className="App">
        
        <div className="container">
            <section id="header">
                <div id="header-bg" className="d-flex align-items-center justify-content-between">
                    <Link to="/">
                        <img className="l_black lazyload" data-src="./assets/logo/shailesh-black.webp" src="Shailesh_logo"  alt="Shailesh_logo" width="122px" height="49px" style={{display: "block"}}/>
                        <img className="l_white lazyload" data-src="./assets/logo/shailesh-white.webp"  alt="Shailesh_logo" width="122px" height="49px" style={{display: "none"}}/>
                    </Link>
                    <div id="btn-grp-header" className="d-flex align-items-center">
                        <a href="https://www.linkedin.com/in/shailesh-prajapati-8054a762/" id="btn-a" name="shailesh_likdedin" role="button" className="d-flex align-items-center justify-content-center" title="Linkedin">
                            <i className="la la-linkedin-square"></i>
                        </a>
                        <a href="https://github.com/programmershailesh" id="btn-b" name="shailesh_github" role="button" className="d-flex align-items-center justify-content-center" title="GitHub">
                            <i className="la la-github-square"></i>
                        </a>
                        <a id="colr-mode-toggle" role="button" name="dark_mode" className="d-flex align-items-center">
                            <i  className="la la-adjust"></i>
                            <p id="labl-colr-mode">Dark Mode</p>
                        </a>
                        <a id="colr-mode-toggle" role="button" onClick={openMenu} className="menuOpen d-flex align-items-center" title="Menu" >
                            <i className="la la-navicon" id="ic-colr-mode"></i>
                            <p id="labl-colr-mode">Menu</p>
                        </a>
                    </div>
                </div>
            </section>

        
            <div id="myNav" className="overlay overlay-menu">
                <a href="#" className="closebtn menuClose" onClick={closeMenu}>&times;</a>
                <div className="overlay-content">
                    <Link to="/about-me" className="menu_anchor" onClick={closeMenu}>About Me</Link>
                    <Link to="/career-histroy" className="menu_anchor" onClick={closeMenu}>Career Histroy</Link>
                    <Link to="/portfolio" className="menu_anchor" onClick={closeMenu}>Portfolio</Link>
                    <Link to="/certificate" className="menu_anchor" onClick={closeMenu}>Certificate</Link>
                    <Link to="/blog" className="menu_anchor" onClick={closeMenu}>Blog</Link>
                </div>
            </div>
        </div>
    </div>
  );
}

export default App;