import React from 'react';

function Home() {
  return (
    <div className="App">
        <div className="container">
            <section id="hero">
                <h1 id="tagline">It’s just got harder<br/>to build a bad product.</h1>
                <div className="d-flex align-items-center">
                    <div className="menu_desktop">
                        <a href="skype:prajapati.shailesh8" className="btn btn-primary" role="button">
                            <span className="label-long">Let’s Chat <span className="btn-content-secondary">&#8202;—&#8202;Skype</span> </span>
                            <span className="label-short">Let’s Chat</span>
                        </a>
                        <a href="mailto:programmer.shailesh@gmail.com" className="btn btn-secondary" role="button">Send an Email</a>
                    </div>
                </div>
            </section>
            <section id="serv-showcase">
                <div className="row">
                    <div className="col-md-4">
                        <div id="serv-tl-writing">

                            <img data-src="./assets/img/ic-writing.svg" className="lazyload"  alt="Writing_img"/>
                            <h3>UX Writing</h3>
                            <p>Your hatred of writing dates back to when Hary&#x200a;—&#x200a;your filthy friend from high
                                school&#x200a;—&#x200a;didn’t let you copy his assignment. Save yourself the trouble this
                                time around.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div id="serv-tl-writing">
                            <img data-src="./assets/img/ic-design.svg" className="lazyload"  alt="design_icon"/>
                            <h3>Interface Design</h3>
                            <p>Here’s what saves a substandard product: superior UI design. Beautiful typography,
                                distinctive icons, and more animations than cash spent on development&#x200a;—&#x200a;you
                                name it.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div id="serv-tl-writing">
                            <img data-src="./assets/img/ic-development.svg" className="lazyload"  alt="Development_img"/>
                            <h3>Web Development</h3>
                            <p>Your disgusting mates use website builders. Wait for the inevitable
                                question&#x200a;—&#x200a;“What template did you use‽”&#x200a;—&#x200a;and watch them slowly
                                die inside as they learn you <i>didn’t</i>.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
  );
}

export default Home;