import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="App">
        <div className="container">
            <section id="hero" className="text-center sectionTitle">
                <h1 id="tagline">About Me</h1>
                {/* <div className="d-flex align-items-center">
                    <a href="https://t.me/programmershailesh" className="btn btn-primary" role="button"><span
                            className="label-long">Let’s Chat<span
                                className="btn-content-secondary">&#8202;—&#8202;Telegram</span></span><span
                            className="label-short">Let’s Chat</span></a>
                    <a href="mailto:programmer.shailesh@gmail.com" className="btn btn-secondary" role="button">Send an Email</a>
                </div> */}
            </section>
            <section id="serv-showcase">
                <div className="row">
                    <div id="serv-tl-writing">
                        <div className="border-bottom">
                            <img data-src="assets/logo/shailesh_prajapati.webp" className="lazyload about_me_logo" alt="Shailesh Prajapati"/>
                            <h2>SHAILESH PRAJAPATI</h2>
                            <p>Hey all, greetings for the day.</p>

                            <p>This is Shailesh P. living in the ‘Diamond City’ Surat, which is located on the banks of Tapi river. I have completed my Masters of Computer Application from Uka Tarsadiya University, Bardoli, in 2015 and bachelors degree in the same field from Bhagwan Mahavir College of Computer Application, Surat.</p>

                            <p>I started my career from Jun 2015 as a Web Developer at Ebizz Infotech and moved on to land a position as a Senior Web Developer at Trigger Systems from March 2017. During my professional career, I have tried my hands on various frameworks and technologies and in some of them, I’ve become quite proficient.</p>

                            <p>Programming has been my most favourite activity during my leisure time. Apart from that, I love to read technology journals to keep myself updated with the current trends in the IT industry. To add more, I’m decently inclined towards Bollywood music and being at remote locations where the scenic beauty soothes my mind and gives me an opportunity to escape from the concrete and silicon jungle.</p>
                        </div>
                        <div>
                            <span className="tag_programming">## Programming ##</span>
                            <div>
                                <span className="tag">PHP</span>
                                <span className="tag">CodeIgniter</span>
                                <span className="tag">Laravel</span>
                                <span className="tag">WordPress</span>
                                <span className="tag">Joomla</span>
                                <span className="tag">WooCommerce</span>
                                <span className="tag">Symphony</span>
                                <span className="tag">Slim</span>
                                <span className="tag">Twig</span>
                                <span className="tag">ASP.NET</span>
                                <span className="tag">C#</span>
                                <span className="tag">.NET</span>
                                <span className="tag">VB .NET</span>
                            </div>

                            <span className="tag_programming">## Front-End Frameworks ##</span>
                            <div>
                                <span className="tag">Angular JS</span>
                                <span className="tag">React JS</span>
                                <span className="tag">TypeScript JS</span>
                                <span className="tag">BackBone JS</span>
                            </div>

                            <span className="tag_programming">## Mobile Application Cross platform Frameworks ##</span>
                            <div>
                                <span className="tag">PhoneGap</span>
                                <span className="tag">React Native JS</span>
                            </div>

                            <span className="tag_programming">## Ui/UX ##</span>
                            <div>
                                <span className="tag">Figma</span>
                                <span className="tag">Adobe XD</span>
                                <span className="tag">Balsamiq</span>
                                <span className="tag">InVison</span>
                                <span className="tag">Studio Design</span>
                                <span className="tag">Mockflow</span>
                            </div>

                            <span className="tag_programming">## Database ##</span>
                            <div>
                                <span className="tag">MYSQL</span>
                                <span className="tag">SQL Server</span>
                                <span className="tag">Mongo DB</span>
                                <span className="tag">Cassandra</span>
                            </div>

                            <span className="tag_programming">## User - Interface ##</span>
                            <div>
                                <span className="tag">Bootstrap</span>
                                <span className="tag">CSS</span>
                                <span className="tag">CSS3</span>
                                <span className="tag">HTML</span>
                                <span className="tag">HTML4</span>
                                <span className="tag">Pure CSS</span>
                            </div>

                            <span className="tag_programming">## Script ##</span>
                            <div>
                                <span className="tag">Javascript</span>
                                <span className="tag">Jquery</span>
                                <span className="tag">Jquery Alert</span>
                                <span className="tag">Google Script API</span>
                                <span className="tag">SweetAlert</span>
                                <span className="tag">Chart JS</span>
                                <span className="tag">AMChart JS</span>
                                <span className="tag">DataTable JS</span>
                            </div>

                            <span className="tag_programming">## Lightweight Communication ##</span>
                            <div>
                                <span className="tag">MQTT</span>
                                <span className="tag">WebSocket</span>
                            </div>

                            <span className="tag_programming">## Script Management Tool ##</span>
                            <div>
                                <span className="tag">GitHub</span>
                                <span className="tag">GitLab</span>
                                <span className="tag">BitBucket</span>
                            </div>

                            <span className="tag_programming">## Project Management Tool ##</span>
                            <div>
                                <span className="tag">Jira</span>
                                <span className="tag">Trello</span>
                                <span className="tag">Assana</span>
                            </div>

                            <span className="tag_programming">## Local Enviroment Software ##</span>
                            <div>
                                <span className="tag">Docker</span>
                                <span className="tag">Xampp</span>
                                <span className="tag">Lamp</span>
                                <span className="tag">Wamp</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="hero">
                    <div id="btn-grp-header" className="d-flex align-items-center">
                        <Link id="colr-mode-toggle" role="button" className="d-flex align-items-center ml-0" to="/">
                            <i id="ic-colr-mode" className="la la-arrow-left"></i>
                            <p id="labl-colr-mode">Back to home</p>
                        </Link>
                    </div>
            </section>
        </div>
    </div>
  );
}

export default Home;