import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos'; 
import 'aos/dist/aos.css';

class workHistroy extends React.Component {
    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	}
    render() {
        return (
            <div className="container">
                <section id="hero" className="text-center sectionTitle">
                    <h1 id="tagline">Career Histroy</h1>
                    {/* <div className="d-flex align-items-center">
                        <a href="https://t.me/programmershailesh" className="btn btn-primary" role="button">
                            <span className="label-long">Let’s Chat<span className="btn-content-secondary">&#8202;—&#8202;Telegram</span></span>
                            <span  className="label-short">Let’s Chat</span>
                        </a>
                        <a href="mailto:programmer.shailesh@gmail.com" className="btn btn-secondary" role="button">Send an Email</a>
                    </div> */}
                </section>
                <section id="serv-showcase">
                    <div className="row">
                        <div id="serv-tl-writing" data-aos="fade-up" data-aos-delay="100">
                            <div className="border-bottom">
                                <img data-src="./assets/img/work/trigger.webp" className="lazyload" alt="Trigger.systems"/>
                                <h3>Trigger System</h3>
                                <p>Trigger.Systems works on tackling the excessive use of water in irrigation, which represents 70% of the fresh water spent globally.</p>

                                <p>The actual control systems used in agriculture and landscape areas normally have an inappropriate water consumption values derived from mismanagement and empirical determination of necessities. </p>

                                <p>Trigger.Systems’s platform eases the control of irrigation systems using calculation models based on weather forecasts and plant conditions which can lead to 40% of water saving. </p>

                                <p>In this platform users can create their own customized solution by using the apps that best represent their systems and with this maximize the efficiency of their systems. Each app is design for a specific purpose and it has different intelligent models that control the systems.</p>

                                <p>
                                    Control all your systems in one unique platform:
                                    <br/>
                                    Irrigation, Pumps, Lakes, Fertilization, Hydroponics, Golf, Energy, Pollution.
                                </p>

                                <p>Save water and energy. </p>
                            </div>
                            <div>
                                <h3 className="bg-foot-color">Work Duration</h3>
                                <p><span className="tag">APRIL - 2017 To Present</span></p>

                                <h3 className="bg-foot-color">Technology</h3>
                                    <span className="tag_programming">## Programming ##</span>
                                    <p>
                                        <span className="tag">PHP</span>
                                        <span className="tag">CodeIgniter</span>
                                        <span className="tag">Twig</span>
                                    </p>

                                    <span className="tag_programming">## Database ##</span>
                                    <p>
                                        <span className="tag">MYSQL</span>
                                    </p>

                                    <span className="tag_programming">## User - Interface ##</span>
                                    <p>
                                        <span className="tag">Bootstrap</span>
                                        <span className="tag">CSS3</span>
                                        <span className="tag">HTML5</span>
                                    </p>

                                    <span className="tag_programming">## Script ##</span>
                                    <p>
                                        <span className="tag">Javascript</span>
                                        <span className="tag">Jquery</span>
                                        <span className="tag">Google API</span>
                                        <span className="tag">SweetAlert</span>
                                        <span className="tag">Chart's API</span>
                                        <span className="tag">MQTT</span>
                                        <span className="tag">Rest API</span>
                                        <span className="tag">WebSocket</span>
                                    </p>

                                    <span className="tag_programming">## Script Management Tool ##</span>
                                    <p>
                                        <span className="tag">Github</span>
                                        <span className="tag">GitLab</span>
                                    </p>

                                    <span className="tag_programming">## Local Enviroment Software ##</span>
                                    <p>
                                        <span className="tag">Docker</span>
                                    </p>
                            </div>
                        </div>

                        <div id="serv-tl-writing" data-aos="fade-up" data-aos-delay="100">

                            <div className="border-bottom">
                                <img data-src="./assets/img/work/ebizz-infotech.webp" className="lazyload" alt="ebizz"/>
                                <h3>Ebizz Infotech</h3>
                            </div>
                            <div>
                                <h3 className="bg-foot-color">Work Duration</h3>
                                <p><span className="tag">Jun - 2015 To March - 2017</span></p>

                                <h3 className="bg-foot-color">Technology</h3>
                                    <span className="tag_programming">## Programming ##</span>
                                    <p>
                                        <span className="tag">PHP</span>
                                        <span className="tag">CodeIgniter</span>
                                        <span className="tag">WordPress</span>
                                        <span className="tag">Laravel</span>
                                        <span className="tag">Slim</span>
                                        <span className="tag">WooCommerce</span>
                                        <span className="tag">React JS</span>
                                        <span className="tag">Angular JS</span>
                                    </p>

                                    <span className="tag_programming">## Database ##</span>
                                    <p>
                                        <span className="tag">MYSQL</span>
                                        <span className="tag">Mongo DB</span>
                                        <span className="tag">Cassandra</span>
                                        <span className="tag">SQL Server</span>
                                    </p>

                                    <span className="tag_programming">## User - Interface ##</span>
                                    <p>
                                        <span className="tag">Bootstrap</span>
                                        <span className="tag">CSS3</span>
                                        <span className="tag">HTML5</span>
                                        <span className="tag">Media Query</span>
                                    </p>

                                    <span className="tag_programming">## Script ##</span>
                                    <p>
                                        <span className="tag">Javascript</span>
                                        <span className="tag">Jquery</span>
                                        <span className="tag">Google API</span>
                                        <span className="tag">SweetAlert</span>
                                        <span className="tag">Chart's API</span>
                                        <span className="tag">MQTT</span>
                                        <span className="tag">Rest API</span>
                                        <span className="tag">WebSocket</span>
                                    </p>

                                    <span className="tag_programming">## Script Management Tool ##</span>
                                    <p>
                                        <span className="tag">GitHub</span>
                                        <span className="tag">GitLab</span>
                                        <span className="tag">BitBucket</span>
                                    </p>

                                    <span className="tag_programming">## Local Enviroment Software ##</span>
                                    <p>
                                        <span className="tag">Docker</span>
                                        <span className="tag">Xampp</span>
                                        <span className="tag">Wamp</span>
                                        <span className="tag">Lamp</span>
                                    </p>

                                    <span className="tag_programming">## Project Management ##</span>
                                    <p>
                                        <span className="tag">Aasana</span>
                                        <span className="tag">Trello</span>
                                    </p>

                                    <span className="tag_programming">## Operating Systems ##</span>
                                    <p>
                                        <span className="tag">Windows</span>
                                        <span className="tag">Linux</span>
                                        <span className="tag">Mac OS</span>
                                    </p>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="hero">
                    <div id="btn-grp-header" className="d-flex align-items-center">
                        <Link id="colr-mode-toggle" role="button" className="d-flex align-items-center ml-0" to="/">
                            <i id="ic-colr-mode" className="la la-arrow-left"></i>
                            <p id="labl-colr-mode">Back to home</p>
                        </Link>
                    </div>
                </section>
            </div>
        );
    }
}

export default workHistroy;